<template>
  <v-autocomplete
    v-model="model"
    :items="entries"
    :loading="isLoading"
    :search-input.sync="search"
    cache-items
    color="white"
    solo
    multiple
    chips 
    label="Search by specific ingredient(s)"
    item-text="Value"
    item-value="Key"
    prepend-icon="fa fa-carrot"
  ></v-autocomplete>
</template>

<script>
import foodService from "@/services/foodService";
export default {
  data: () => ({
    descriptionLimit: 60,
    entries: [],
    isLoading: false,
    model: null,
    lastChangedData: null,
    search: null
  }),
  props: ['foodIds'],
  /*model: {
    prop: 'foodItems',
    event: 'change'
  },*/
  mounted() {
    //console.log('foodItems', this.foodItems);
  },
  computed: {
  },
  watch: {
    search(val) {
      // Items have already been loaded
      //if (this.items.length > 0) return;

      // Items have already been requested
      if (this.isLoading) return;

      console.log("val", val, "search", this.search);
      this.isLoading = true;

      foodService
        .autocomplete(val)
        .then(res => {
          console.log("result:", res, "data", res.data);
          this.count = res.data.meta.count;
          this.entries = res.data.data;
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },
    foodIds(val) {
      if (!Array.isArray(val)) {
        val = [val];
      }
      console.log("Setting ingredients:", val);
      this.lastChangedData = val;
      this.model = val;
      foodService.list(val).then(res => {
        this.entries = res.data.data.map(x => { return {Key: x.id, Value: x.name}});
      });
    },
    model(val) {
      //this.nutrientId = val.id;
      var sameData = (val||[]).every(x => (this.lastChangedData||[]).includes(x) ) && (this.lastChangedData||[]).every(x => (val||[]).includes(x) );
      if (!sameData) {
        console.log("Raising Change event", val);
        this.$emit('change', val);
        this.lastChangedData = val;
      }
    }
  }
};  
</script>