<template>
  <v-autocomplete
    v-model="model"
    :items="entries"
    :loading="isLoading"
    :search-input.sync="search"
    cache-items
    color="white"
    solo
    hide-details
    item-text="name"
    item-value="id"
    label="Sort by nutrient"
    placeholder="Sort by nutrient content"
    no-data-text="Type at least 3 characters"
    prepend-icon="fa fa-flask"
  ></v-autocomplete>
</template>

<script>
import nutrientService from "@/services/nutrientService";
export default {
  data: () => ({
    descriptionLimit: 60,
    entries: [],
    isLoading: false,
    model: null,
    lastChangedId: null,
    search: null
  }),
  props: ['nutrientId'],
  /*model: {
    prop: 'nutrientId',
    event: 'change'
  },*/
  methods: {
    loadNutrient(id) {
      nutrientService.get(id).then(response => {
        this.entries = [response.data];
        this.$emit('loadNutrient', response.data);
        console.log('Loaded nutrients', this.entries);
      }).catch(() => {
        this.entries = [];
      });
    }
  },
  computed: {
  },
  watch: {
    nutrientId(val) {
      console.log('Loading nutrient', val);
      this.lastChangedId = val;
      this.model = val;
      this.loadNutrient(val);
    },
    search(val) {
      // Items have already been loaded
      //if (this.items.length > 0) return;

      // Items have already been requested
      if (this.isLoading) return;

      console.log("val", val, "search", this.search);
      this.isLoading = true;

      nutrientService
        .autocomplete(val)
        .then(res => {
          console.log("result:", res, "data", res.data);
          this.count = res.data.meta.count;
          this.entries = res.data.data;
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },
    model(val) {
      if (val !== this.lastChangedId) {
        console.log("Raising Change event", val);
        this.$emit('change', val);
        this.lastChangedId = val;
      }
    }
  }
};
</script>